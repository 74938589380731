import React, { Fragment } from 'react'
import { Parallax } from '@react-spring/parallax'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Hero from '../views/Hero'
import { BigTitle, Subtitle } from '../elements'

const goBack = () => {
  window.history.back()
}
const goBackStyle = {
  cursor: 'pointer',
  textDecoration: 'underline',
}
const BmiCalculator = () => (
  <Fragment>
    <Layout />
    <Helmet title="404" />
    <Parallax pages={1}>
      <Hero offset={0}>
        <BigTitle>Oops! There is nothing here.</BigTitle>
        <Subtitle style={goBackStyle} onClick={goBack}>
          Go back
        </Subtitle>
      </Hero>
    </Parallax>
  </Fragment>
)

export default BmiCalculator
